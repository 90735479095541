module.exports = {
  title: 'Dev Blog', // Required
  author: 'Karan Nandwani', // Required
  description: 'Sr. Product Manager (Tech) at Amazon',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://karann.dev',
    github: 'https://github.com/karann-msft',
    twitter: 'https://twitter.com/karann9',
    linkedin: 'https://www.linkedin.com/in/karannandwani',
  },
  pathPrefix: '/devblog',
  siteUrl: 'https://karann.dev/',
};
